import React, { useState, useEffect, useRef } from 'react';
import './pending_ads.css';
import { Row, Col, Form, Button, Card, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import { FaSearch, FaRegStar } from 'react-icons/fa'
import LocalIP from "../../LocalIP";
import axios from "axios"
import { Drawer } from "antd"
import { FaFilter } from "react-icons/fa"
import { useHistory, useLocation } from 'react-router-dom';
import { BiSolidDetail } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const Pending_ads = () => {

  const location = useLocation();
  const all_adsRef = useRef(null);
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [model, setModel] = useState('');
  const [manufacture, setManufacture] = useState('');
  const [allads, setAllads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(12);
  const [totalAds, setTotalAds] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [areaSearch, setAreaSearch] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const history = useHistory();

  useEffect(() => onReload(), []);

  const timeAgo = (dateTime) => {
    const now = new Date();
    const past = new Date(dateTime.replace(' ', 'T'));
    const timeDifference = now - past;

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''}`;
    } else {
      return `${weeks} week${weeks !== 1 ? 's' : ''}`;
    }
  }

  const ads_title_limit = (title) => {
    if (title.length > 20) {
      return title.substring(0, 20) + "...";
    }
    return title;
  }

  const onReload = () => {
    const url1 = LocalIP + "ads/approve_pending_ads";
    axios.get(url1, {
      headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
        start: 0,
        length: pageSize,
      }
    }).then((response) => {
      console.log(response["data"])
      setAllads(response["data"])
      setLoading(false)
    });
    const url2 = LocalIP + "ads/approve_pending_ads/total";
    axios.get(url2, {
      headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
        start: 0,
        length: pageSize
      }
    }).then((response) => {
      console.log(response["data"])
      setTotalAds(response["data"][0].total_rows)
      setPagesCount(Math.ceil(response["data"][0].total_rows / pageSize))
      console.log(Math.ceil(response["data"][0].total_rows / pageSize))
      setLoading(false)
    });

  };

  const approveAd = async (id) => {
    const url = LocalIP + "ads/pending_approve_ads";
    console.log(url)
    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
          id: id
        }
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Ads Approve Success!", {
            position: "top-right",
          });
          onReload()
        }
      });
  }

  const rejectAd = async (id) => {
    const url = LocalIP + "ads/pending_reject_ads";
    console.log(url)
    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
          id: id
        }
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Ads Rejected!", {
            position: "top-right",
          });
          onReload()
        }
      });
  }

  const pageData = (page, size) => {
    console.log(page, size)
    setLoading(true)
    setPageNum(page)
    if (page == 1) {
      page = 0;
    } else {
      page = (page - 1) * size
    }
    const url1 = LocalIP + "ads/approve_pending_ads";
    axios.get(url1, {
      headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
        start: page,
        length: size
      }
    }).then((response) => {
      console.log(response["data"])
      setAllads(response["data"])
      setLoading(false)
    });
    if (size !== pageSize) {
      setPageNum(1)
      const url2 = LocalIP + "ads/approve_pending_ads/total";
      axios.get(url2, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }
      }).then((response) => {
        console.log(response["data"])
        setTotalAds(response["data"][0].total_rows)
        setPagesCount(Math.ceil(response["data"][0].total_rows / size))
        console.log(Math.ceil(response["data"][0].total_rows / size))
        setLoading(false)
      });
    }
  }

  const pageSelect = (num) => {
    setPageNum(num)
  }

  const paginationPrint = (count) => {
    const listItems = [];
    for (let i = 1; i <= count; i++) {
      if (((i <= (pageNum + 2)) && (i >= (pageNum - 2))) || (pageNum === 1 && (i <= (pageNum + 4))) || (pageNum === 2 && (i <= (pageNum + 3))) || (pageNum === pagesCount && (i >= (pageNum - 4))) || (pageNum === pagesCount - 1 && (i >= (pageNum - 3)))) {
        listItems.push(<li className={i === pageNum ? 'page-item selected-page' : 'page-item'}>
          <a class="text-dark page-link" onClick={() => { pageSelect(i); pageData(i, pageSize); }} >{i}</a>
        </li>);
      }
    }
    return listItems;
  };

  const date_time_only = (date_time) => {
    const date = new Date(date_time);
    const datePart = date.toISOString().split('T')[0];
    const timePart = date.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return `${datePart} ${timePart}`;
  };

  return (
    <Container>
      <br />
      <div id="pending_ads" ref={all_adsRef} tabIndex={0} className="web-view">
        <h4 className='text-left'>Pending ads</h4>
        {loading ? (<Spinner animation="border" />) : totalAds !== 0 ? (<div>
          <Row><p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p></Row>
          <Row>
            {
              allads.map((res, index) =>
                <Col className="my-ads-card-view">
                  <Card className="my-ads-card-view">
                    <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                    <Card.Body>
                      <Card.Text>
                        <p className="card-title">{res.AD_TITLE}</p>
                        <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                          "D " + res['price_day'] + "AED "
                        )}
                          {res['price_week'] && (
                            "W " + res['price_week'] + "AED "
                          )}
                          {res['price_month'] && (
                            "M " + res['price_month'] + "AED"
                          )}</p>
                        <p title={res.AD_DESC}>{ads_title_limit(res.AD_DESC)}</p>
                      </Card.Text>
                      <Row>
                        <Col lg={2} xs={2}>
                          <a href={"/admin_ads_view/" + res.id} className='btn btn-warning view-details' alt="View Details"><BiSolidDetail /></a>
                        </Col>
                        <Col lg={2} xs={2}>
                          <button onClick={() => approveAd(res.id)} className='btn btn-success view-details' alt="View Details"><IoIosCheckmarkCircle /></button>
                        </Col>
                        <Col lg={2} xs={2}>
                          <button onClick={() => rejectAd(res.id)} className='btn btn-danger view-details' alt="View Details"><IoMdCloseCircle /></button>
                        </Col>
                        <Col lg={4} xs={4} style={{ marginTop: '5px', fontSize: '12px' }}>
                          <span title={date_time_only(res.date_time)}>{timeAgo(res.date_time)}</span>
                        </Col>
                        <Col lg={2} xs={2}>
                          {
                            res.AD_TYPE.toLowerCase() == "rental" ? (<div className="dot green-dot" title="Rental" />) :
                              (
                                res.AD_TYPE.toLowerCase() == "service" ?
                                  (<div className="dot red-dot" title="Service" />) : (<div className="dot blue-dot" title="Trading" />))
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )
            }
            {(allads.length % 3 != 0) && (<Col></Col>)}
          </Row>
          <br />
          {totalAds == 0 ? (<Row></Row>) : (
            <Row>
              <Col>
                <ul class="pagination pagination-l">
                  <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                  </li>
                  {paginationPrint(pagesCount)}
                  <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => { pageSelect(pageNum + 1); pageData(pageNum + 1, pageSize); }}>Next</a>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className='d-flex flex-column align-items-end'>
                  <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="60">60</option>
                  </Form.Control>
                </div>
              </Col>
            </Row>
          )}
        </div>) : (<h1>Don't have any pending ads</h1>)
        }
      </div>
      <div id="pending_ads" className="mobile-view">
        {loading ? (<Spinner animation="border" />) : totalAds !== 0 ? (<div>
          <Row>
            <p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p>
          </Row>
          <Row>
            {
              allads.map((res, index) =>
                <Col className="my-ads-card-view">
                  <Card className="my-ads-card-view">
                    <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                    <Card.Body>
                      <Card.Text>
                        <p className="card-title">{res.AD_TITLE}</p>
                        <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                          "D " + res['price_day'] + "AED "
                        )}
                          {res['price_week'] && (
                            "W " + res['price_week'] + "AED "
                          )}
                          {res['price_month'] && (
                            "M " + res['price_month'] + "AED"
                          )}</p>
                        <p title={res.AD_DESC}>{ads_title_limit(res.AD_DESC)}</p>
                      </Card.Text>
                      <Row>
                        <Col lg={2} xs={2}>
                          <a href={"/admin_ads_view/" + res.id} className='btn btn-warning view-details' alt="View Details"><BiSolidDetail /></a>
                        </Col>
                        <Col lg={2} xs={2}>
                          <button onClick={() => approveAd(res.id)} className='btn btn-success view-details' alt="View Details"><IoIosCheckmarkCircle /></button>
                        </Col>
                        <Col lg={2} xs={2}>
                          <button onClick={() => rejectAd(res.id)} className='btn btn-danger view-details' alt="View Details"><IoMdCloseCircle /></button>
                        </Col>
                        <Col lg={4} xs={4} style={{ marginTop: '5px', fontSize: '12px' }}>
                          <span title={date_time_only(res.date_time)}>{timeAgo(res.date_time)}</span>
                        </Col>
                        <Col lg={2} xs={2}>
                          {
                            res.AD_TYPE.toLowerCase() == "rental" ? (<div className="dot green-dot" title="Rental" />) :
                              (
                                res.AD_TYPE.toLowerCase() == "service" ?
                                  (<div className="dot red-dot" title="Service" />) : (<div className="dot blue-dot" title="Trading" />))
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )
            }
          </Row>
          <br />
          {totalAds == 0 ? (<Row></Row>) : (
            <Row>
              <Col>
                <ul class="pagination pagination-l">
                  <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                  </li>
                  {paginationPrint(pagesCount)}
                  <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => { pageSelect(pageNum + 1); pageData(pageNum + 1, pageSize); }}>Next</a>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className='d-flex flex-column align-items-end'>
                  <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="60">60</option>
                  </Form.Control>
                </div>
              </Col>
            </Row>
          )}
        </div>) : (<h1>Don't have any pending ads</h1>)}
      </div>
    </Container >
  );
}

export default Pending_ads;
