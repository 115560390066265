import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import './edit_ads.css';
import Container from "react-bootstrap/Container";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom"

function Edit_ads(props) {

  let history = useHistory();
  const [ads_id, setAds_id] = useState('');
  const [machinery, setMachinery] = useState([]);
  const [sub_machinery_temp, setSub_machinery_temp] = useState([]);
  const [sub_machinery, setSub_machinery] = useState([]);
  const [image1, setImage1] = useState(["/upload_image.png"]);
  const [image2, setImage2] = useState(["/upload_image.png"]);
  const [image3, setImage3] = useState(["/upload_image.png"]);
  const [image4, setImage4] = useState(["/upload_image.png"]);
  const [image5, setImage5] = useState(["/upload_image.png"]);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [adsTypeRentalData, setAdsTypeRentalData] = useState(true);
  const [adsTypeCategoryData, setAdsTypeCategoryData] = useState(false);
  const [type, setType] = useState('rental');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [manufacture, setManufacture] = useState('');
  const [area, setArea] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [rentalPeriod, setRentalPeriod] = useState('');
  const [rentalCost, setRentalCost] = useState('');
  const [pickupOrDelivery, setpickupOrDelivery] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [makeError, setMakeError] = useState('');
  const [modelError, setModelError] = useState('');
  const [manufactureError, setManufactureError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');
  const [rentalPeriodError, setRentalPeriodError] = useState('');
  const [rentalCostError, setRentalCostError] = useState('');
  const [pickupOrDeliveryError, setPickupOrDeliveryError] = useState('');
  const [imageError, setImageError] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryError, setSubCategoryError] = useState('');
  const [services, setServices] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const setTypeForm = (e) => {
    setType(e.target.value)
    setCategory("")
    if (e.target.value == "Rental" || e.target.value == "Trading") {
      setAdsTypeRentalData(true)
      setAllCategory(machinery)
    } else {
      setAdsTypeRentalData(false)
      setAdsTypeCategoryData(false)
      setAllCategory(services)
    }
  }

  useEffect(() => onReload(), []);

  const onReload = () => {
    console.log("new")
    const url = LocalIP + "machinery/";
    axios.get(url).then((response) => {
      console.log(response["data"])
      setMachinery(response["data"])
    });
    const url1 = LocalIP + "machinery/sub/";
    axios.get(url1).then((response) => {
      console.log(response["data"])
      setSub_machinery_temp(response["data"])
    });
    const url2 = LocalIP + "machinery/services/";
    axios.get(url2).then((response) => {
      console.log(response["data"])
      setServices(response["data"])
    });
    const url3 = LocalIP + "ads/one/" + props.match.params.id;
    axios.get(url3).then((response) => {
      console.log(response["data"][0])
      setAds_id(response["data"][0].id)
      setTitle(response["data"][0].AD_TITLE)
      setType(response["data"][0].AD_TYPE)
      setCategory(response["data"][0].CATEGORY)
      setSubCategory(response["data"][0].subCategory)
      setDescription(response["data"][0].AD_DESC)
      setMake(response["data"][0].MAKE)
      setModel(response["data"][0].MODEL)
      setManufacture(response["data"][0].YOM)
      setArea(response["data"][0].AREA)
      setPhone(response["data"][0].TEL_NO)
      setWhatsapp(response["data"][0].MOB)
      setRentalCost(response["data"][0].RENTAL_COST)
      setRentalPeriod(response["data"][0].RENTAL_PERIOD)
      setpickupOrDelivery(response["data"][0].PIC_DEL)
      if (response["data"][0].IMG_1 !== "") {
        setImage1(response["data"][0].IMG_1)
      }
      if (response["data"][0].IMG_2 !== "") {
        setImage2(response["data"][0].IMG_2)
      }
      if (response["data"][0].IMG_3 !== "") {
        setImage3(response["data"][0].IMG_3)
      }
      if (response["data"][0].IMG_4 !== "") {
        setImage4(response["data"][0].IMG_4)
      }
      if (response["data"][0].IMG_5 !== "") {
        setImage5(response["data"][0].IMG_5)
      }
    });
  };

  const setCategoryForm = (e) => {
    setCategory(e.target.value)
    if (type == "Rental" || type == "Trading") {
      const subs_cat = sub_machinery_temp.filter(one => one.category_id * 1 === e.target.value * 1);
      if (subs_cat.length !== 0) {
        setSub_machinery(subs_cat)
        setAdsTypeCategoryData(true)
      } else {
        setSub_machinery([])
        setAdsTypeCategoryData(false)
      }
    }
  }

  const onChangeHandler = event => {
    if (image1 == "/upload_image.png" || image2 == "/upload_image.png" || image3 == "/upload_image.png" || image4 == "/upload_image.png" || image5 == "/upload_image.png") {
      const data = new FormData()
      data.append('file', event.target.files[0])
      axios.post(LocalIP + "file/upload", data).then(res => {
        console.log(res.data)
        if (image1 == "/upload_image.png") {
          setImage1(res.data)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (fileInputRef1.current) {
            fileInputRef1.current.value = ''
          }
        } else if (image2 == "/upload_image.png") {
          setImage2(res.data)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (fileInputRef1.current) {
            fileInputRef1.current.value = ''
          }
        } else if (image3 == "/upload_image.png") {
          setImage3(res.data)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (fileInputRef1.current) {
            fileInputRef1.current.value = ''
          }
        } else if (image4 == "/upload_image.png") {
          setImage4(res.data)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (fileInputRef1.current) {
            fileInputRef1.current.value = ''
          }
        } else if (image5 == "/upload_image.png") {
          setImage5(res.data)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (fileInputRef1.current) {
            fileInputRef1.current.value = ''
          }
        } else {
          toast.error("You cannot be upload more than 5 images!", {
            position: "top-right",
          });
        }
      })
    } else {
      toast.error("You cannot be upload more than 5 images!", {
        position: "top-right",
      });
    }
  }

  const clickImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearAll = () => {
    setTitle('')
    setType('')
    setCategory('')
    setDescription('')
    setMake('')
    setModel('')
    setManufacture('')
    setArea('')
    setPhone('')
    setWhatsapp('')
    setRentalCost('')
    setRentalPeriod('')
    setpickupOrDelivery('')
    setTypeError('')
    setCategoryError('')
    setDescriptionError('')
    setMakeError('')
    setModelError('')
    setManufactureError('')
    setAreaError('')
    setPhoneError('')
    setWhatsappError('')
    setRentalCostError('')
    setRentalPeriodError('')
    setPickupOrDeliveryError('')
    setImageError('')
    setImage1("/upload_image.png")
    setImage2("/upload_image.png")
    setImage3("/upload_image.png")
    setImage4("/upload_image.png")
    setImage5("/upload_image.png")
  }

  const validation = () => {
    var Error = false;

    if (type === "") {
      setTypeError("Ads Type Required!")
      Error = true;
    } else {
      setTypeError("")
    }

    if (category === "") {
      setCategoryError("Ads Category Required!")
      Error = true;
    } else {
      setCategoryError("")
    }

    if (subCategory === "") {
      setSubCategoryError("Ads Sub Category Required!")
      Error = true;
    } else {
      setSubCategoryError("")
    }

    if (title === "") {
      setTitleError("Ads Title Required!")
      Error = true;
    } else {
      setTitleError("")
    }

    if (description === "") {
      setDescriptionError("Ads Description Required!")
      Error = true;
    } else {
      setDescriptionError("")
    }

    if (type == "rental" && make === "") {
      setMakeError("Ads Make Required!")
      Error = true;
    } else {
      setMakeError("")
    }

    if (type == "rental" && model === "") {
      setModelError("Ads Model Required!")
      Error = true;
    } else {
      setModelError("")
    }

    if (image1 == "/upload_image.png") {
      setImageError("Ads Image Required!")
      Error = true;
    } else {
      setImageError("")
    }

    if (type == "rental" && manufacture === "") {
      setManufactureError("Ads Manufacture Required!")
      Error = true;
    } else {
      setManufactureError("")
    }

    if (area === "") {
      setAreaError("Ads Area Required!")
      Error = true;
    } else {
      setAreaError("")
    }

    if (phone === "") {
      setPhoneError("Ads Phone Required!")
      Error = true;
    } else {
      setPhoneError("")
    }

    if (email === "") {
      setEmailError("Ads Email Required!")
      Error = true;
    } else {
      setEmailError("")
    }

    if (whatsapp === "") {
      setWhatsappError("Ads Mobile Required!")
      Error = true;
    } else {
      setWhatsappError("")
    }

    if (type == "rental" && rentalPeriod === "") {
      setRentalPeriodError("Ads Rental Period Required!")
      Error = true;
    } else {
      setRentalPeriodError("")
    }

    if (type == "rental" && rentalCost === "") {
      setRentalCostError("Ads Rental Cost Required!")
      Error = true;
    } else {
      setRentalCostError("")
    }

    if (type == "rental" && pickupOrDelivery === "") {
      setPickupOrDeliveryError("Ads Pickup Or Delivery Required!")
      Error = true;
    } else {
      setPickupOrDeliveryError("")
    }

    if (Error) {
      return false;
    }

    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (validation()) {

      var img2 = "", img3 = "", img4 = "", img5 = "";
      if (image2 == "/upload_image.png") {
        img2 = ""
      } else {
        img2 = image2
      }

      if (image3 == "/upload_image.png") {
        img3 = ""
      } else {
        img3 = image3
      }

      if (image4 == "/upload_image.png") {
        img4 = ""
      } else {
        img4 = image4
      }

      if (image5 == "/upload_image.png") {
        img5 = ""
      } else {
        img5 = image5
      }

      const url = LocalIP + "ads/edit/"+ads_id;
      const data = JSON.stringify({
        title: title,
        type: type,
        category: category,
        subCategory: subCategory,
        description: description,
        make: make,
        model: model,
        manufacture: manufacture,
        image1: image1,
        image2: img2,
        image3: img3,
        image4: img4,
        image5: img5,
        area: area,
        phone: phone,
        whatsapp: whatsapp,
        email: email,
        rentalPeriod: rentalPeriod,
        rentalCost: rentalCost,
        pickupOrDelivery: pickupOrDelivery,
        user_id: localStorage.getItem("id")
      });
      console.log(data);
      await axios
        .put(url, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.success === "success") {
            toast.success("The ad was updated successfully!", {
              position: "top-right",
            });
            clearAll()
            history.push("/my_ads")
          } else {
            toast.error("The ad edit unsuccessfull!", {
              position: "top-right",
            });
          }
        });

    }
  };

  return (
    <Container>
      <br />
      <div id="edit_ads" className="web-view">
        <div className="card-view-post-ads">
          <h2 className="mb-4">Edit Ad</h2>
          <Form noValidate onSubmit={SubmitForm} >
            <Form.Group controlId="type" className="mb-3">
              <Form.Label>Advertisement Type</Form.Label>
              <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                <option value="Rental">Rental</option>
                <option value="Service">Service</option>
                <option value="Trading">Trading</option>
              </Form.Control>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{typeError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="category" className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" value={category} onChange={setCategoryForm} className={`form-control-select-ads ${category === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    {
                      machinery.map((res, index) =>
                        <option value={res.id}>{res.name}</option>
                      )
                    }
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{categoryError}</div>
                </Form.Group>
              )
            }
            {
              adsTypeCategoryData && (
                <Form.Group controlId="subCategory" className="mb-3">
                  <Form.Label>Sub Category</Form.Label>
                  <Form.Control as="select" value={subCategory} onChange={e => { setSubCategory(e.target.value) }} className={`form-control-select-ads ${subCategory === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    {
                      sub_machinery.map((res, index) =>
                        <option value={res.id}>{res.sub_category_name}</option>
                      )
                    }
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{subCategoryError}</div>
                </Form.Group>
              )
            }
            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Advertisement Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={e => { setTitle(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{titleError}</div>
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
              {
                adsTypeRentalData ? (<Form.Label>Machine Description</Form.Label>) : (<Form.Label>Description</Form.Label>)}
              <Form.Control
                as="textarea"
                rows={4}
                value={description}
                placeholder="Enter machine description"
                onChange={e => { setDescription(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{descriptionError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="make" className="mb-3">
                  <Form.Label>Make</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter make"
                    value={make}
                    onChange={e => { setMake(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{makeError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="model" className="mb-3">
                      <Form.Label>Model</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter model"
                        value={model}
                        onChange={e => { setModel(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{modelError}</div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="yom" className="mb-3">
                      <Form.Label>Year of Manufacture</Form.Label>
                      <Form.Control as="select" value={manufacture} onChange={e => { setManufacture(e.target.value) }} className={`form-control-select-ads ${manufacture === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {Array.from({ length: (new Date().getFullYear() * 1) - 1960 + 1 }, (_, index) => (
                          <option key={index} value={(new Date().getFullYear() * 1) - index}>{(new Date().getFullYear() * 1) - index}</option>
                        ))}
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{manufactureError}</div>
                    </Form.Group>
                  </Col>
                </Row>)
            }
            <Form.Group controlId="photos" className="mb-3">
              <Form.Label>Upload Photos (up to 5)</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={onChangeHandler} ref={fileInputRef} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{imageError}</div>
              <div className="mt-3">
                <Row>
                  <Col>
                    <img
                      src={image1}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage1("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image2}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage2("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image3}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage3("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image4}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage4("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image5}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage5("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                </Row>
              </div>
            </Form.Group>
            <Form.Group controlId="area" className="mb-3">
              <Form.Label>Area Available for Work</Form.Label>
              <Form.Control as="select" className={`form-control-select-ads ${area === "" ? "selected-default-option" : ""}`} value={area} onChange={e => { setArea(e.target.value) }}>
                <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                <option value="1">Sharjah</option>
                <option value="2">Abu Dhabi</option>
              </Form.Control>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{areaError}</div>
            </Form.Group>
            <Form.Group controlId="phone_number" className="mb-3">
              <Form.Label>Telephone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter telephone number"
                value={phone}
                onChange={e => { setPhone(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{phoneError}</div>
            </Form.Group>
            <Form.Group controlId="whatsapp" className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile"
                value={whatsapp}
                onChange={e => { setWhatsapp(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{whatsappError}</div>
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => { setEmail(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="rentalPeriod" className="mb-3">
                  <Form.Label>Rental Period</Form.Label>
                  <Form.Control as="select" onChange={e => { setRentalPeriod(e.target.value) }} value={rentalPeriod} className={`form-control-select-ads ${rentalPeriod === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalPeriodError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="rentalCost" className="mb-3">
                  <Form.Label>Rental Cost</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter rental cost"
                    value={rentalCost}
                    onChange={e => { setRentalCost(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalCostError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="pickupOrDelivery" className="mb-3">
                  <Form.Label>Delivery Option</Form.Label>
                  <Form.Control as="select" onChange={e => { setpickupOrDelivery(e.target.value) }} value={pickupOrDelivery} className={`form-control-select-ads ${pickupOrDelivery === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="pickup">Pickup</option>
                    <option value="delivery">Delivery</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{pickupOrDeliveryError}</div>
                </Form.Group>
              )
            }
            <div className='ads-post-btn'>
              <Button type="submit" className="mt-4 w-25 btn btn-warning">
                Edit Ad
              </Button>
            </div>
          </Form>
        </div>
        <br />
      </div>
      <div id="post_ads" className="mobile-view">
        <div className="card-view-post-ads-mobile">
          <h2 className="mb-4">Edit Ad</h2>
          <Form noValidate onSubmit={SubmitForm} >
            <Form.Group controlId="type" className="mb-3">
              <Form.Label>Advertisement Type</Form.Label>
              <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                <option value="Rental">Rental</option>
                <option value="Service">Service</option>
              </Form.Control>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{typeError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="category" className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" value={category} onChange={e => { setCategory(e.target.value) }} className={`form-control-select-ads ${category === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    {
                      machinery.map((res, index) =>
                        <option value={res.id}>{res.name}</option>
                      )
                    }
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{categoryError}</div>
                </Form.Group>
              )}
            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Advertisement Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={e => { setTitle(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{titleError}</div>
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
              {
                adsTypeRentalData ? (<Form.Label>Machine Description</Form.Label>) : (<Form.Label>Description</Form.Label>)}
              <Form.Control
                as="textarea"
                rows={4}
                value={description}
                placeholder="Enter machine description"
                onChange={e => { setDescription(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{descriptionError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="make" className="mb-3">
                  <Form.Label>Make</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter make"
                    value={make}
                    onChange={e => { setMake(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{makeError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="model" className="mb-3">
                      <Form.Label>Model</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter model"
                        value={model}
                        onChange={e => { setModel(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{modelError}</div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="yom" className="mb-3">
                      <Form.Label>Year of Manufacture</Form.Label>
                      <Form.Control as="select" value={manufacture} onChange={e => { setManufacture(e.target.value) }} className={`form-control-select-ads ${manufacture === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {Array.from({ length: 2024 - 1960 + 1 }, (_, index) => (
                          <option key={index} value={2024 - index}>{2024 - index}</option>
                        ))}
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{manufactureError}</div>
                    </Form.Group>
                  </Col>
                </Row>)
            }
            <Form.Group controlId="photos" className="mb-3">
              <Form.Label>Upload Photos (up to 5)</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={onChangeHandler} ref={fileInputRef} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{imageError}</div>
              <div className="mt-3">
                <Row>
                  <Col>
                    <img
                      src={image1}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage1("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image2}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage2("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image3}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage3("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image4}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage4("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                  <Col>
                    <img
                      src={image5}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                    <button className='btn btn-danger' onClick={() => setImage5("/upload_image.png")} ><MdDelete /></button>
                  </Col>
                </Row>
              </div>
            </Form.Group>
            <Form.Group controlId="area" className="mb-3">
              <Form.Label>Area Available for Work</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter area available for work"
                value={area}
                onChange={e => { setArea(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{areaError}</div>
            </Form.Group>
            <Form.Group controlId="phone_number" className="mb-3">
              <Form.Label>Telephone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter telephone number"
                value={phone}
                onChange={e => { setPhone(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{phoneError}</div>
            </Form.Group>
            <Form.Group controlId="whatsapp" className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile"
                value={whatsapp}
                onChange={e => { setWhatsapp(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{whatsappError}</div>
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => { setEmail(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="rentalPeriod" className="mb-3">
                  <Form.Label>Rental Period</Form.Label>
                  <Form.Control as="select" onChange={e => { setRentalPeriod(e.target.value) }} value={rentalPeriod} className={`form-control-select-ads ${rentalPeriod === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalPeriodError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="rentalCost" className="mb-3">
                  <Form.Label>Rental Cost</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter rental cost"
                    value={rentalCost}
                    onChange={e => { setRentalCost(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalCostError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="pickupOrDelivery" className="mb-3">
                  <Form.Label>Delivery Option</Form.Label>
                  <Form.Control as="select" onChange={e => { setpickupOrDelivery(e.target.value) }} value={pickupOrDelivery} className={`form-control-select-ads ${pickupOrDelivery === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="pickup">Pickup</option>
                    <option value="delivery">Delivery</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{pickupOrDeliveryError}</div>
                </Form.Group>
              )
            }
            <div className='ads-post-btn'>
              <Button type="submit" className="mt-4 w-25 btn btn-warning">
                Edit Ad
              </Button>
            </div>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Edit_ads;
