// Footer.js
import React from 'react';
import '../footer/footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaHome, FaEnvelope, FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'

const Footer = () => {
  return (
    <footer>
      <div className="footer text-light p-4">
        <Container className="mt-4">
          <Row className='py-3'>
            <Col className="text-left" md={6}>
              <h5>ABOUT</h5>
              <p className='footer-about-p site-text-color'>At Machinery Souk.com, we recognize the complexities of navigating the heavy machinery trading and rental industry, especially in a constantly evolving market. Founded by Tharindu Hashan Eknathgedara, a seasoned professional from Sri Lanka, our company was born from the need to seamlessly connect machinery owners, traders, and contractors across United Arab Emirates.</p>
              <ul className="list-unstyled">
                <li><FaHome className="contact-icon footer-contact-icons" /><span className='site-text-color'>Plot no 3634 Al Sajaa Industrial City , Sharjah, United Arab Emirates.</span></li>
                <li><a href="mailto:support@machinerysouk.com" className="d-flex align-items-center footer-contact-a site-text-color"><FaEnvelope className="contact-icon footer-contact-icons" />Email: support@machinerysouk.com</a></li>
                <li><a href="tel:+94711111111" className="d-flex align-items-center footer-contact-a site-text-color"><FaPhoneAlt className="contact-icon footer-contact-icons" /> Phone: +94 7 14 206 973/4</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>CATEGORIES</h5>
              <ul className="list-unstyled">
                <li><a href="/faq" className="footer-link site-text-color">Home Appliances</a></li>
                <li><a href="/stay-safe" className="footer-link site-text-color">Computers</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Fashion</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Electronics</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Mobile Phones</a></li>
                <li><a href="/contact" className="footer-link site-text-color">Tools</a></li>
              </ul>
            </Col>
            <Col className="text-left" md={3}>
              <h5>QUICK LINKS</h5>
              <ul className="list-unstyled">
                <li><a href="/about-us" className="footer-link site-text-color">Home</a></li>
                <li><a href="/terms" className="footer-link site-text-color">Products</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">Portfolio</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">About Us</a></li>
                <li><a href="/privacy-policy" className="footer-link site-text-color">Contact Us</a></li>
              </ul>
            </Col>
          </Row>
          <hr />
          <div>
            <Row className="d-flex align-items-center">
              <Col md={6} className="text-left">
                <a href='https://www.linkedin.com/company/wizolve-technologies/'><p className="mb-0 site-text-color">Copyright © {new Date().getFullYear()} All Rights Reserved by Wizolve Technologies (Pvt) Ltd</p></a>
              </Col>
              <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                <FaFacebook className="contact-icon footer-social-icons" />
                <FaTwitter className="contact-icon footer-social-icons" />
                <TbWorld className="contact-icon footer-social-icons" />
                <FaLinkedinIn className="contact-icon footer-social-icons" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
