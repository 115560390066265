import React, { useState, useEffect, useRef } from 'react'
import './about_us.css'
import { Col, Row } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import LocalIP from "../../LocalIP"
import axios from "axios"

const About_us = () => {
  return (
    <Container>
      <br />
      <div id="about_us" className="web-view">
        <h1>About Us</h1><br />
        <p>
        At Machinery Souk.com, we recognize the complexities of navigating the heavy machinery trading and rental industry, especially in a constantly evolving market. Founded by Tharindu Hashan Eknathgedara, a seasoned professional from Sri Lanka, our company was born from the need to seamlessly connect machinery owners, traders, and contractors across United Arab Emirates.</p>
        <br />
        <p>
        Tharindu hails from a family with over 35 years of experience in the heavy machinery industry. With 16 years of hands-on experience himself, he has worked in machinery rentals, earthworks, and road construction. After earning his degree in Construction Management from the University of Birmingham, Tharindu joined his family’s business, S&K Engineering Enterprises (PVT) Ltd. However, the economic crisis in Sri Lanka in 2021 led him to relocate to Dubai, where he established S&K Heavy Equipment Rental and Trading LLC to pursue new opportunities in the machinery sector.
        </p><br />
        
        <p>
        Machinery Souk.com was born from Tharindu's determination to overcome the challenges of finding reliable partners in a new country. Today, we are committed to supporting others in the industry by providing a platform that enables machinery traders and contractors to connect, ensuring fair deals and trusted partnerships.
        </p><br />
        
        <p>
        Join us in creating a reliable and efficient marketplace for heavy machinery in the heart of United Arab Emirates.
        </p>
      </div>
      <div id="about_us" className="mobile-view">

      </div>
    </Container>
  );
}

export default About_us;
