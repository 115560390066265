import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, Spinner, InputGroup, Table } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import './post_ads.css';
import Container from "react-bootstrap/Container";
import LocalIP from "../../LocalIP";
import axios from "axios"

const Post_ads = () => {

  const [machinery, setMachinery] = useState([]);
  const [sub_machinery_temp, setSub_machinery_temp] = useState([]);
  const [sub_machinery, setSub_machinery] = useState([]);
  const [image1, setImage1] = useState(["/upload_image.png"]);
  const [image2, setImage2] = useState(["/upload_image.png"]);
  const [image3, setImage3] = useState(["/upload_image.png"]);
  const [image4, setImage4] = useState(["/upload_image.png"]);
  const [image5, setImage5] = useState(["/upload_image.png"]);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [adsTypeRentalData, setAdsTypeRentalData] = useState(true);
  const [adsTypeCategoryData, setAdsTypeCategoryData] = useState(false);
  const [type, setType] = useState('Rental');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [manufacture, setManufacture] = useState('');
  const [area, setArea] = useState([]);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [rentalPeriod, setRentalPeriod] = useState('');
  const [rentalCost, setRentalCost] = useState('');
  const [pickupOrDelivery, setpickupOrDelivery] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [usage, setUsage] = useState('');
  const [usageError, setUsageError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [makeError, setMakeError] = useState('');
  const [modelError, setModelError] = useState('');
  const [manufactureError, setManufactureError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');
  const [rentalPeriodError, setRentalPeriodError] = useState('');
  const [rentalCostError, setRentalCostError] = useState('');
  const [pickupOrDeliveryError, setPickupOrDeliveryError] = useState('');
  const [imageError, setImageError] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryError, setSubCategoryError] = useState('');
  const [areas, setAreas] = useState([]);
  const [loadingImage1, setLoadingImage1] = useState(false);
  const [loadingImage2, setLoadingImage2] = useState(false);
  const [loadingImage3, setLoadingImage3] = useState(false);
  const [loadingImage4, setLoadingImage4] = useState(false);
  const [loadingImage5, setLoadingImage5] = useState(false);
  const [services, setServices] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [telCountryCode, setTelCountryCode] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [dayPrice, setDayPrice] = useState('');
  const [weekPrice, setWeekPrice] = useState('');
  const [monthPrice, setMonthPrice] = useState('');
  const [areasName, setAreasName] = useState([]);

  const setTypeForm = (e) => {
    setType(e.target.value)
    setCategory("")
    if (e.target.value == "Rental" || e.target.value == "Trading") {
      setAdsTypeRentalData(true)
      setAllCategory(machinery)
    } else {
      setAdsTypeRentalData(false)
      setAdsTypeCategoryData(false)
      setAllCategory(services)
    }
  }

  useEffect(() => onReload(), []);

  const onReload = () => {
    const url = LocalIP + "machinery/";
    axios.get(url).then((response) => {
      console.log(response["data"])
      setMachinery(response["data"])
      setAllCategory(response["data"])
    });
    const url1 = LocalIP + "machinery/sub/";
    axios.get(url1).then((response) => {
      console.log(response["data"])
      setSub_machinery_temp(response["data"])
    });
    const url2 = LocalIP + "machinery/area/";
    axios.get(url2).then((response) => {
      console.log(response["data"])
      setAreas(response["data"])
    });
    const url3 = LocalIP + "machinery/services/";
    axios.get(url3).then((response) => {
      console.log(response["data"])
      setServices(response["data"])
    });
    setEmail(localStorage.getItem("email"))

    axios.get('https://ipapi.co/json/')
      .then(response => {
        setTelCountryCode(response.data.country_calling_code.replace('+', ''))
        setMobileCountryCode(response.data.country_calling_code.replace('+', ''))
      })
      .catch(error => {
        console.error('Error fetching the IP geolocation:', error);
      });
  };

  const areaCheckboxChange = (event) => {
    const value = event.target.value;
    const names = event.target.name
    setAreasName((prevSelected) =>
      prevSelected.includes(names)
        ? prevSelected.filter((item) => item !== names)
        : [...prevSelected, names]
    );
    setArea((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const setCategoryForm = (e) => {
    setCategory(e.target.value)
    if (type == "Rental" || type == "Trading") {
      const subs_cat = sub_machinery_temp.filter(one => one.category_id * 1 === e.target.value * 1);
      if (subs_cat.length !== 0) {
        setSub_machinery(subs_cat)
        setAdsTypeCategoryData(true)
      } else {
        setSub_machinery([])
        setAdsTypeCategoryData(false)
      }
    }
  }

  const setTitleForm = (value) => {
    value = value.split(' ').map(
      word => {
        if (word === word.toLowerCase()) {
          console.log(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        } else {
          return word
        }
      }).join(' ');

    setTitle(value);
  }

  const onChangeHandler = event => {
    setTitleForm(title)
    if (image1 == "/upload_image.png") {
      setLoadingImage1(true)
    } else if (image2 == "/upload_image.png") {
      setLoadingImage2(true)
    } else if (image3 == "/upload_image.png") {
      setLoadingImage3(true)
    } else if (image4 == "/upload_image.png") {
      setLoadingImage4(true)
    } else if (image5 == "/upload_image.png") {
      setLoadingImage5(true)
    }
    if (loadingImage1 == false && loadingImage2 == false && loadingImage3 == false && loadingImage4 == false && loadingImage5 == false) {

      if (image1 == "/upload_image.png" || image2 == "/upload_image.png" || image3 == "/upload_image.png" || image4 == "/upload_image.png" || image5 == "/upload_image.png") {
        const data = new FormData()
        data.append('file', event.target.files[0])
        axios.post(LocalIP + "file/upload", data).then(res => {
          console.log(res.data)
          setLoadingImage1(false)
          setLoadingImage2(false)
          setLoadingImage3(false)
          setLoadingImage4(false)
          setLoadingImage5(false)
          if (image1 == "/upload_image.png") {
            setImage1(res.data)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            if (fileInputRef1.current) {
              fileInputRef1.current.value = ''
            }
          } else if (image2 == "/upload_image.png") {
            setImage2(res.data)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            if (fileInputRef1.current) {
              fileInputRef1.current.value = ''
            }
          } else if (image3 == "/upload_image.png") {
            setImage3(res.data)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            if (fileInputRef1.current) {
              fileInputRef1.current.value = ''
            }
          } else if (image4 == "/upload_image.png") {
            setImage4(res.data)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            if (fileInputRef1.current) {
              fileInputRef1.current.value = ''
            }
          } else if (image5 == "/upload_image.png") {
            setImage5(res.data)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            if (fileInputRef1.current) {
              fileInputRef1.current.value = ''
            }
          } else {
            toast.error("You cannot be upload more than 5 images!", {
              position: "top-right",
            });
          }
        })
      } else {
        toast.error("You cannot be upload more than 5 images!", {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please Wait For Complete Uploading!", {
        position: "top-right",
      });
    }
  }

  const clickImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearAll = () => {
    setTitle('')
    setUsage('')
    setType('')
    setCategory('')
    setDescription('')
    setMake('')
    setModel('')
    setManufacture('')
    setArea('')
    setPhone('')
    setWhatsapp('')
    setRentalCost('')
    setRentalPeriod('')
    setpickupOrDelivery('')
    setTypeError('')
    setCategoryError('')
    setDescriptionError('')
    setMakeError('')
    setModelError('')
    setManufactureError('')
    setAreaError('')
    setPhoneError('')
    setWhatsappError('')
    setRentalCostError('')
    setRentalPeriodError('')
    setPickupOrDeliveryError('')
    setImageError('')
    setImage1("/upload_image.png")
    setImage2("/upload_image.png")
    setImage3("/upload_image.png")
    setImage4("/upload_image.png")
    setImage5("/upload_image.png")
    setDayPrice('')
    setMonthPrice('')
    setWeekPrice('')
    setAreasName([])
  }

  const priceAdd = () => {

    if (rentalPeriod === "") {
      setRentalPeriodError("Ads Rental Period Required!")
    } else {
      if (rentalCost === "") {
        setRentalCostError("Ads Rental Cost Required!")
      } else {
        if (rentalPeriod === "Day") {
          setDayPrice(rentalCost)
        } else if (rentalPeriod === "Week") {
          setWeekPrice(rentalCost)
        } else {
          setMonthPrice(rentalCost)
        }
        setRentalPeriod('')
        setRentalCost('')
      }
    }

  }

  const validation = () => {
    var Error = false;

    if (title === "") {
      setTitleError("Ads Title Required!")
      Error = true;
    } else {
      setTitleError("")
    }

    if (type === "") {
      setTypeError("Ads Type Required!")
      Error = true;
    } else {
      setTypeError("")
    }

    if (description === "") {
      setDescriptionError("Ads Description Required!")
      Error = true;
    } else {
      setDescriptionError("")
    }

    if (type == "Rental" || type == "Trading") {
      if (category === "") {
        setCategoryError("Ads Category Required!")
        Error = true;
      } else {
        setCategoryError("")
      }

      if (subCategory === "" && sub_machinery.length !== 0) {
        setSubCategoryError("Ads Sub Category Required!")
        Error = true;
      } else {
        setSubCategoryError("")
      }

      if (make === "") {
        setMakeError("Ads Make Required!")
        Error = true;
      } else {
        setMakeError("")
      }

      if (model === "") {
        setModelError("Ads Model Required!")
        Error = true;
      } else {
        setModelError("")
      }

      if (manufacture === "") {
        setManufactureError("Ads Manufacture Required!")
        Error = true;
      } else {
        setManufactureError("")
      }

      if (pickupOrDelivery === "") {
        setPickupOrDeliveryError("Ads Pickup Or Delivery Required!")
        Error = true;
      } else {
        setPickupOrDeliveryError("")
      }

      if (type == "Trading" && usage === "") {
        setUsageError("Ads Usage(hours) Required!")
        Error = true;
      } else {
        setUsageError("")
      }

      if (monthPrice === "" && dayPrice === "" && weekPrice === "") {
        setRentalCostError("Price Required!")
        Error = true;
      } else {
        setRentalCostError("")
      }
    }

    if (image1 == "/upload_image.png") {
      setImageError("Ads Image Required!")
      Error = true;
    } else {
      setImageError("")
    }

    if (area.length === 0) {
      setAreaError("Ads Area Required!")
      Error = true;
    } else {
      setAreaError("")
    }

    if (phone === "") {
      setPhoneError("Ads Phone Required!")
      Error = true;
    } else {
      setPhoneError("")
    }

    if (email === "") {
      setEmailError("Ads Email Required!")
      Error = true;
    } else {
      setEmailError("")
    }

    if (whatsapp === "") {
      setWhatsappError("Ads Mobile Required!")
      Error = true;
    } else {
      setWhatsappError("")
    }

    if (Error) {
      return false;
    }

    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    setTitleForm(title)
    if (validation()) {
      console.log("test")

      var img2 = "", img3 = "", img4 = "", img5 = "";
      if (image2 == "/upload_image.png") {
        img2 = ""
      } else {
        img2 = image2
      }

      if (image3 == "/upload_image.png") {
        img3 = ""
      } else {
        img3 = image3
      }

      if (image4 == "/upload_image.png") {
        img4 = ""
      } else {
        img4 = image4
      }

      if (image5 == "/upload_image.png") {
        img5 = ""
      } else {
        img5 = image5
      }

      const url = LocalIP + "ads/new";
      const data = JSON.stringify({
        title: title,
        type: type,
        category: category,
        subCategory: subCategory,
        description: description,
        make: make,
        model: model,
        manufacture: manufacture,
        image1: image1,
        image2: img2,
        image3: img3,
        image4: img4,
        image5: img5,
        area: area,
        telCode:telCountryCode,
        phone:phone,
        mobileCode:mobileCountryCode,
        whatsapp:whatsapp,
        email: email,
        rentalPeriod: rentalPeriod,
        rentalCost: rentalCost,
        pickupOrDelivery: pickupOrDelivery,
        dayPrice: dayPrice,
        weekPrice: weekPrice,
        monthPrice: monthPrice,
        usage: usage,
        user_id: localStorage.getItem("id")
      });
      console.log(data);
      await axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.success === "success") {
            toast.success("The advertisement was posted successfully!", {
              position: "top-right",
            });
            clearAll()
          } else {
            toast.error("The advertisement post unsuccessfull!", {
              position: "top-right",
            });
          }
        });

    }
  };

  return (
    <Container>
      <br />
      <div id="post_ads" className="web-view">
        <div className="post-ads-form-box hero">
          <h2 className="mb-4">Post Ad</h2>
          <Form noValidate onSubmit={SubmitForm}>
            <Row>
              <Col lg={12} md={12}>
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Advertisement Title<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={e => { setTitle(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{titleError}</div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="type" className="mb-3">
                  <Form.Label>Advertisement Type<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                    <option value="Rental">Rental</option>
                    <option value="Service">Service</option>
                    <option value="Trading">Trading</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{typeError}</div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="area" className="mb-3">
                  <Form.Label>Area Available for Work<span className='red-color-label'>*</span></Form.Label>
                  <div className="dropdown form-control">
                    <div className='form-control-select-ads'>
                      {areasName.length !== 0 ? (areasName.join(', ')) : ("Select Areas")}
                    </div>
                    <div className="dropdown-content">
                      {
                        areas.map((res, index) =>
                          <div className='d-flex dropdown-bottom-box' key={res.id}>
                            <label class="form-check-label dropdown-bottom-label" htmlFor={`checkbox-${res.id}`} key={res.id}>
                              {res.a_name}
                            </label>
                            <input
                              class="form-check-input ms-auto"
                              type="checkbox"
                              id={`checkbox-${res.id}`}
                              value={res.id}
                              name={res.a_name}
                              onChange={(e) => areaCheckboxChange(e)}
                              checked={areasName.includes(res.a_name) ? (true) : (false)}
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{areaError}</div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="category" className="mb-3">
                  <Form.Label>Category<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control as="select" value={category} onChange={setCategoryForm} className={`form-control-select-ads ${category === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    {
                      allCategory.map((res, index) =>
                        <option value={res.id}>{res.name}</option>
                      )
                    }
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{categoryError}</div>
                </Form.Group>
              </Col>
              {
                adsTypeCategoryData && (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="subCategory" className="mb-3">
                      <Form.Label>Sub Category<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control as="select" value={subCategory} onChange={e => { setSubCategory(e.target.value) }} className={`form-control-select-ads ${subCategory === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {
                          sub_machinery.map((res, index) =>
                            <option value={res.id}>{res.sub_category_name}</option>
                          )
                        }
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{subCategoryError}</div>
                    </Form.Group>
                  </Col>
                )
              }
              {
                adsTypeRentalData && (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="make" className="mb-3">
                      <Form.Label>Make<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter make"
                        value={make}
                        onChange={e => { setMake(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{makeError}</div>
                    </Form.Group>
                  </Col>)
              }
              <Col lg={12} md={12}>
                <Form.Group controlId="description" className="mb-3">
                  {
                    adsTypeRentalData ? (<Form.Label>Machine Description<span className='red-color-label'>*</span></Form.Label>) : (<Form.Label>Description<span className='red-color-label'>*</span></Form.Label>)}
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={description}
                    placeholder="Enter machine description"
                    onChange={e => { setDescription(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{descriptionError}</div>
                </Form.Group>
              </Col>
              {
                adsTypeRentalData && (
                  <Col md={6}>
                    <Form.Group controlId="model" className="mb-3">
                      <Form.Label>Model<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter model"
                        value={model}
                        onChange={e => { setModel(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{modelError}</div>
                    </Form.Group>
                  </Col>)
              }
              {
                adsTypeRentalData && (
                  <Col md={6}>
                    <Form.Group controlId="yom" className="mb-3">
                      <Form.Label>Year of Manufacture<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control as="select" value={manufacture} onChange={e => { setManufacture(e.target.value) }} className={`form-control-select-ads ${manufacture === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {Array.from({ length: (new Date().getFullYear() * 1) - 1960 + 1 }, (_, index) => (
                          <option key={index} value={(new Date().getFullYear() * 1) - index}>{(new Date().getFullYear() * 1) - index}</option>
                        ))}
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{manufactureError}</div>
                    </Form.Group>
                  </Col>)
              }
              <Col lg={12} md={12}>
                <Form.Group controlId="photos" className="mb-3">
                  <Form.Label>Upload Photos (up to 5)<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={onChangeHandler} ref={fileInputRef} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{imageError}</div>
                  <div className="mt-3">
                    <Row>
                      <Col>
                        {loadingImage1 ? (<Spinner animation="border" />) : (
                          <img
                            src={image1}
                            className="img-thumbnail"
                            style={{ width: 'auto', marginRight: '10px' }}
                            onClick={clickImageUpload}
                          />
                        )}
                      </Col>
                      <Col>
                        {loadingImage2 ? (<Spinner animation="border" />) : (
                          <img
                            src={image2}
                            className="img-thumbnail"
                            style={{ width: 'auto', marginRight: '10px' }}
                            onClick={clickImageUpload}
                          />
                        )}
                      </Col>
                      <Col>
                        {loadingImage3 ? (<Spinner animation="border" />) : (
                          <img
                            src={image3}
                            className="img-thumbnail"
                            style={{ width: 'auto', marginRight: '10px' }}
                            onClick={clickImageUpload}
                          />
                        )}
                      </Col>
                      <Col>
                        {loadingImage4 ? (<Spinner animation="border" />) : (
                          <img
                            src={image4}
                            className="img-thumbnail"
                            style={{ width: 'auto', marginRight: '10px' }}
                            onClick={clickImageUpload}
                          />
                        )}
                      </Col>
                      <Col>
                        {loadingImage5 ? (<Spinner animation="border" />) : (
                          <img
                            src={image5}
                            className="img-thumbnail"
                            style={{ width: 'auto', marginRight: '10px' }}
                            onClick={clickImageUpload}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="phone_number" className="mb-3">
                  <Form.Label>Telephone Number<span className='red-color-label'>*</span></Form.Label>
                  <InputGroup>
                    <Form.Select
                      aria-label="Country code"
                      style={{ maxWidth: '120px' }}
                      value={telCountryCode}
                      onChange={(e) => setTelCountryCode(e.target.value)}
                    >
                      <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                      <option data-countryCode="AD" value="376">Andorra (+376)</option>
                      <option data-countryCode="AO" value="244">Angola (+244)</option>
                      <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                      <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                      <option data-countryCode="AR" value="54">Argentina (+54)</option>
                      <option data-countryCode="AM" value="374">Armenia (+374)</option>
                      <option data-countryCode="AW" value="297">Aruba (+297)</option>
                      <option data-countryCode="AU" value="61">Australia (+61)</option>
                      <option data-countryCode="AT" value="43">Austria (+43)</option>
                      <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                      <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                      <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                      <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                      <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                      <option data-countryCode="BY" value="375">Belarus (+375)</option>
                      <option data-countryCode="BE" value="32">Belgium (+32)</option>
                      <option data-countryCode="BZ" value="501">Belize (+501)</option>
                      <option data-countryCode="BJ" value="229">Benin (+229)</option>
                      <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                      <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                      <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                      <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                      <option data-countryCode="BW" value="267">Botswana (+267)</option>
                      <option data-countryCode="BR" value="55">Brazil (+55)</option>
                      <option data-countryCode="BN" value="673">Brunei (+673)</option>
                      <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                      <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                      <option data-countryCode="BI" value="257">Burundi (+257)</option>
                      <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                      <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                      <option data-countryCode="CA" value="1">Canada (+1)</option>
                      <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                      <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                      <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                      <option data-countryCode="CL" value="56">Chile (+56)</option>
                      <option data-countryCode="CN" value="86">China (+86)</option>
                      <option data-countryCode="CO" value="57">Colombia (+57)</option>
                      <option data-countryCode="KM" value="269">Comoros (+269)</option>
                      <option data-countryCode="CG" value="242">Congo (+242)</option>
                      <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                      <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                      <option data-countryCode="HR" value="385">Croatia (+385)</option>
                      <option data-countryCode="CU" value="53">Cuba (+53)</option>
                      <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                      <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                      <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                      <option data-countryCode="DK" value="45">Denmark (+45)</option>
                      <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                      <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                      <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                      <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                      <option data-countryCode="EG" value="20">Egypt (+20)</option>
                      <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                      <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                      <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                      <option data-countryCode="EE" value="372">Estonia (+372)</option>
                      <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                      <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                      <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                      <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                      <option data-countryCode="FI" value="358">Finland (+358)</option>
                      <option data-countryCode="FR" value="33">France (+33)</option>
                      <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                      <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                      <option data-countryCode="GA" value="241">Gabon (+241)</option>
                      <option data-countryCode="GM" value="220">Gambia (+220)</option>
                      <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                      <option data-countryCode="DE" value="49">Germany (+49)</option>
                      <option data-countryCode="GH" value="233">Ghana (+233)</option>
                      <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                      <option data-countryCode="GR" value="30">Greece (+30)</option>
                      <option data-countryCode="GL" value="299">Greenland (+299)</option>
                      <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                      <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                      <option data-countryCode="GU" value="671">Guam (+671)</option>
                      <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                      <option data-countryCode="GN" value="224">Guinea (+224)</option>
                      <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                      <option data-countryCode="GY" value="592">Guyana (+592)</option>
                      <option data-countryCode="HT" value="509">Haiti (+509)</option>
                      <option data-countryCode="HN" value="504">Honduras (+504)</option>
                      <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                      <option data-countryCode="HU" value="36">Hungary (+36)</option>
                      <option data-countryCode="IS" value="354">Iceland (+354)</option>
                      <option data-countryCode="IN" value="91">India (+91)</option>
                      <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                      <option data-countryCode="IR" value="98">Iran (+98)</option>
                      <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                      <option data-countryCode="IE" value="353">Ireland (+353)</option>
                      <option data-countryCode="IL" value="972">Israel (+972)</option>
                      <option data-countryCode="IT" value="39">Italy (+39)</option>
                      <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                      <option data-countryCode="JP" value="81">Japan (+81)</option>
                      <option data-countryCode="JO" value="962">Jordan (+962)</option>
                      <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                      <option data-countryCode="KE" value="254">Kenya (+254)</option>
                      <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                      <option data-countryCode="KP" value="850">Korea North (+850)</option>
                      <option data-countryCode="KR" value="82">Korea South (+82)</option>
                      <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                      <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                      <option data-countryCode="LA" value="856">Laos (+856)</option>
                      <option data-countryCode="LV" value="371">Latvia (+371)</option>
                      <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                      <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                      <option data-countryCode="LR" value="231">Liberia (+231)</option>
                      <option data-countryCode="LY" value="218">Libya (+218)</option>
                      <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                      <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                      <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                      <option data-countryCode="MO" value="853">Macao (+853)</option>
                      <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                      <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                      <option data-countryCode="MW" value="265">Malawi (+265)</option>
                      <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                      <option data-countryCode="MV" value="960">Maldives (+960)</option>
                      <option data-countryCode="ML" value="223">Mali (+223)</option>
                      <option data-countryCode="MT" value="356">Malta (+356)</option>
                      <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                      <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                      <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                      <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                      <option data-countryCode="MX" value="52">Mexico (+52)</option>
                      <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                      <option data-countryCode="MD" value="373">Moldova (+373)</option>
                      <option data-countryCode="MC" value="377">Monaco (+377)</option>
                      <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                      <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                      <option data-countryCode="MA" value="212">Morocco (+212)</option>
                      <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                      <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                      <option data-countryCode="NA" value="264">Namibia (+264)</option>
                      <option data-countryCode="NR" value="674">Nauru (+674)</option>
                      <option data-countryCode="NP" value="977">Nepal (+977)</option>
                      <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                      <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                      <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                      <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                      <option data-countryCode="NE" value="227">Niger (+227)</option>
                      <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                      <option data-countryCode="NU" value="683">Niue (+683)</option>
                      <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                      <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                      <option data-countryCode="NO" value="47">Norway (+47)</option>
                      <option data-countryCode="OM" value="968">Oman (+968)</option>
                      <option data-countryCode="PW" value="680">Palau (+680)</option>
                      <option data-countryCode="PA" value="507">Panama (+507)</option>
                      <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                      <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                      <option data-countryCode="PE" value="51">Peru (+51)</option>
                      <option data-countryCode="PH" value="63">Philippines (+63)</option>
                      <option data-countryCode="PL" value="48">Poland (+48)</option>
                      <option data-countryCode="PT" value="351">Portugal (+351)</option>
                      <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                      <option data-countryCode="QA" value="974">Qatar (+974)</option>
                      <option data-countryCode="RE" value="262">Reunion (+262)</option>
                      <option data-countryCode="RO" value="40">Romania (+40)</option>
                      <option data-countryCode="RU" value="7">Russia (+7)</option>
                      <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                      <option data-countryCode="SM" value="378">San Marino (+378)</option>
                      <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                      <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                      <option data-countryCode="SN" value="221">Senegal (+221)</option>
                      <option data-countryCode="CS" value="381">Serbia (+381)</option>
                      <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                      <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                      <option data-countryCode="SG" value="65">Singapore (+65)</option>
                      <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                      <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                      <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                      <option data-countryCode="SO" value="252">Somalia (+252)</option>
                      <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                      <option data-countryCode="ES" value="34">Spain (+34)</option>
                      <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                      <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                      <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                      <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                      <option data-countryCode="SD" value="249">Sudan (+249)</option>
                      <option data-countryCode="SR" value="597">Suriname (+597)</option>
                      <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                      <option data-countryCode="SE" value="46">Sweden (+46)</option>
                      <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                      <option data-countryCode="SI" value="963">Syria (+963)</option>
                      <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                      <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                      <option data-countryCode="TH" value="66">Thailand (+66)</option>
                      <option data-countryCode="TG" value="228">Togo (+228)</option>
                      <option data-countryCode="TO" value="676">Tonga (+676)</option>
                      <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                      <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                      <option data-countryCode="TR" value="90">Turkey (+90)</option>
                      <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                      <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                      <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                      <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                      <option data-countryCode="UG" value="256">Uganda (+256)</option>
                      <option data-countryCode="GB" value="44">UK (+44)</option>
                      <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                      <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                      <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                      <option data-countryCode="US" value="1">USA (+1)</option>
                      <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                      <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                      <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                      <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                      <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                      <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                      <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                      <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                      <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                      <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                      <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                      <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                    </Form.Select>
                    <Form.Control
                      type="text"
                      placeholder="Enter telephone number"
                      value={phone}
                      onChange={e => {
                        const value = e.target.value;
                        const numericValue = value.replace(/\D/g, '');
                        setPhone(numericValue);
                      }}
                    />
                  </InputGroup>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{phoneError}</div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="whatsapp" className="mb-3">
                  <Form.Label>Mobile<span className='red-color-label'>*</span></Form.Label>
                  <InputGroup>
                    <Form.Select
                      aria-label="Country code"
                      style={{ maxWidth: '120px' }}
                      value={mobileCountryCode}
                      onChange={(e) => setMobileCountryCode(e.target.value)}
                    >
                      <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                      <option data-countryCode="AD" value="376">Andorra (+376)</option>
                      <option data-countryCode="AO" value="244">Angola (+244)</option>
                      <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                      <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                      <option data-countryCode="AR" value="54">Argentina (+54)</option>
                      <option data-countryCode="AM" value="374">Armenia (+374)</option>
                      <option data-countryCode="AW" value="297">Aruba (+297)</option>
                      <option data-countryCode="AU" value="61">Australia (+61)</option>
                      <option data-countryCode="AT" value="43">Austria (+43)</option>
                      <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                      <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                      <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                      <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                      <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                      <option data-countryCode="BY" value="375">Belarus (+375)</option>
                      <option data-countryCode="BE" value="32">Belgium (+32)</option>
                      <option data-countryCode="BZ" value="501">Belize (+501)</option>
                      <option data-countryCode="BJ" value="229">Benin (+229)</option>
                      <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                      <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                      <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                      <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                      <option data-countryCode="BW" value="267">Botswana (+267)</option>
                      <option data-countryCode="BR" value="55">Brazil (+55)</option>
                      <option data-countryCode="BN" value="673">Brunei (+673)</option>
                      <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                      <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                      <option data-countryCode="BI" value="257">Burundi (+257)</option>
                      <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                      <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                      <option data-countryCode="CA" value="1">Canada (+1)</option>
                      <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                      <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                      <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                      <option data-countryCode="CL" value="56">Chile (+56)</option>
                      <option data-countryCode="CN" value="86">China (+86)</option>
                      <option data-countryCode="CO" value="57">Colombia (+57)</option>
                      <option data-countryCode="KM" value="269">Comoros (+269)</option>
                      <option data-countryCode="CG" value="242">Congo (+242)</option>
                      <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                      <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                      <option data-countryCode="HR" value="385">Croatia (+385)</option>
                      <option data-countryCode="CU" value="53">Cuba (+53)</option>
                      <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                      <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                      <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                      <option data-countryCode="DK" value="45">Denmark (+45)</option>
                      <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                      <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                      <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                      <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                      <option data-countryCode="EG" value="20">Egypt (+20)</option>
                      <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                      <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                      <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                      <option data-countryCode="EE" value="372">Estonia (+372)</option>
                      <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                      <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                      <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                      <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                      <option data-countryCode="FI" value="358">Finland (+358)</option>
                      <option data-countryCode="FR" value="33">France (+33)</option>
                      <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                      <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                      <option data-countryCode="GA" value="241">Gabon (+241)</option>
                      <option data-countryCode="GM" value="220">Gambia (+220)</option>
                      <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                      <option data-countryCode="DE" value="49">Germany (+49)</option>
                      <option data-countryCode="GH" value="233">Ghana (+233)</option>
                      <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                      <option data-countryCode="GR" value="30">Greece (+30)</option>
                      <option data-countryCode="GL" value="299">Greenland (+299)</option>
                      <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                      <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                      <option data-countryCode="GU" value="671">Guam (+671)</option>
                      <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                      <option data-countryCode="GN" value="224">Guinea (+224)</option>
                      <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                      <option data-countryCode="GY" value="592">Guyana (+592)</option>
                      <option data-countryCode="HT" value="509">Haiti (+509)</option>
                      <option data-countryCode="HN" value="504">Honduras (+504)</option>
                      <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                      <option data-countryCode="HU" value="36">Hungary (+36)</option>
                      <option data-countryCode="IS" value="354">Iceland (+354)</option>
                      <option data-countryCode="IN" value="91">India (+91)</option>
                      <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                      <option data-countryCode="IR" value="98">Iran (+98)</option>
                      <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                      <option data-countryCode="IE" value="353">Ireland (+353)</option>
                      <option data-countryCode="IL" value="972">Israel (+972)</option>
                      <option data-countryCode="IT" value="39">Italy (+39)</option>
                      <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                      <option data-countryCode="JP" value="81">Japan (+81)</option>
                      <option data-countryCode="JO" value="962">Jordan (+962)</option>
                      <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                      <option data-countryCode="KE" value="254">Kenya (+254)</option>
                      <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                      <option data-countryCode="KP" value="850">Korea North (+850)</option>
                      <option data-countryCode="KR" value="82">Korea South (+82)</option>
                      <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                      <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                      <option data-countryCode="LA" value="856">Laos (+856)</option>
                      <option data-countryCode="LV" value="371">Latvia (+371)</option>
                      <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                      <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                      <option data-countryCode="LR" value="231">Liberia (+231)</option>
                      <option data-countryCode="LY" value="218">Libya (+218)</option>
                      <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                      <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                      <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                      <option data-countryCode="MO" value="853">Macao (+853)</option>
                      <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                      <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                      <option data-countryCode="MW" value="265">Malawi (+265)</option>
                      <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                      <option data-countryCode="MV" value="960">Maldives (+960)</option>
                      <option data-countryCode="ML" value="223">Mali (+223)</option>
                      <option data-countryCode="MT" value="356">Malta (+356)</option>
                      <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                      <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                      <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                      <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                      <option data-countryCode="MX" value="52">Mexico (+52)</option>
                      <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                      <option data-countryCode="MD" value="373">Moldova (+373)</option>
                      <option data-countryCode="MC" value="377">Monaco (+377)</option>
                      <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                      <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                      <option data-countryCode="MA" value="212">Morocco (+212)</option>
                      <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                      <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                      <option data-countryCode="NA" value="264">Namibia (+264)</option>
                      <option data-countryCode="NR" value="674">Nauru (+674)</option>
                      <option data-countryCode="NP" value="977">Nepal (+977)</option>
                      <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                      <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                      <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                      <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                      <option data-countryCode="NE" value="227">Niger (+227)</option>
                      <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                      <option data-countryCode="NU" value="683">Niue (+683)</option>
                      <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                      <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                      <option data-countryCode="NO" value="47">Norway (+47)</option>
                      <option data-countryCode="OM" value="968">Oman (+968)</option>
                      <option data-countryCode="PW" value="680">Palau (+680)</option>
                      <option data-countryCode="PA" value="507">Panama (+507)</option>
                      <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                      <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                      <option data-countryCode="PE" value="51">Peru (+51)</option>
                      <option data-countryCode="PH" value="63">Philippines (+63)</option>
                      <option data-countryCode="PL" value="48">Poland (+48)</option>
                      <option data-countryCode="PT" value="351">Portugal (+351)</option>
                      <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                      <option data-countryCode="QA" value="974">Qatar (+974)</option>
                      <option data-countryCode="RE" value="262">Reunion (+262)</option>
                      <option data-countryCode="RO" value="40">Romania (+40)</option>
                      <option data-countryCode="RU" value="7">Russia (+7)</option>
                      <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                      <option data-countryCode="SM" value="378">San Marino (+378)</option>
                      <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                      <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                      <option data-countryCode="SN" value="221">Senegal (+221)</option>
                      <option data-countryCode="CS" value="381">Serbia (+381)</option>
                      <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                      <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                      <option data-countryCode="SG" value="65">Singapore (+65)</option>
                      <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                      <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                      <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                      <option data-countryCode="SO" value="252">Somalia (+252)</option>
                      <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                      <option data-countryCode="ES" value="34">Spain (+34)</option>
                      <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                      <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                      <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                      <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                      <option data-countryCode="SD" value="249">Sudan (+249)</option>
                      <option data-countryCode="SR" value="597">Suriname (+597)</option>
                      <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                      <option data-countryCode="SE" value="46">Sweden (+46)</option>
                      <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                      <option data-countryCode="SI" value="963">Syria (+963)</option>
                      <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                      <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                      <option data-countryCode="TH" value="66">Thailand (+66)</option>
                      <option data-countryCode="TG" value="228">Togo (+228)</option>
                      <option data-countryCode="TO" value="676">Tonga (+676)</option>
                      <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                      <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                      <option data-countryCode="TR" value="90">Turkey (+90)</option>
                      <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                      <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                      <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                      <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                      <option data-countryCode="UG" value="256">Uganda (+256)</option>
                      <option data-countryCode="GB" value="44">UK (+44)</option>
                      <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                      <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                      <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                      <option data-countryCode="US" value="1">USA (+1)</option>
                      <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                      <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                      <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                      <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                      <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                      <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                      <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                      <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                      <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                      <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                      <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                      <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                    </Form.Select>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mobile"
                      value={whatsapp}
                      onChange={e => {
                        const value = e.target.value
                        const numericValue = value.replace(/\D/g, '')
                        setWhatsapp(numericValue)
                      }}
                    />
                  </InputGroup>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{whatsappError}</div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email address<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={e => { setEmail(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
                </Form.Group>
              </Col>
              {
                type == "Trading" && (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="usage" className="mb-3">
                      <Form.Label>Usage (hours)<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Usage (hours)"
                        value={usage}
                        onChange={e => { setUsage(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{usageError}</div>
                    </Form.Group>
                  </Col>)
              }
              {
                adsTypeRentalData && (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="pickupOrDelivery" className="mb-3">
                      <Form.Label>Delivery Option<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control as="select" onChange={e => { setpickupOrDelivery(e.target.value) }} value={pickupOrDelivery} className={`form-control-select-ads ${pickupOrDelivery === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        <option value="Pickup">Pickup</option>
                        <option value="Delivery">Delivery</option>
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{pickupOrDeliveryError}</div>
                    </Form.Group>
                  </Col>
                )
              }
              {
                adsTypeRentalData && (
                  <hr />
                )}
              {
                adsTypeRentalData && (
                  <Col lg={6} md={6}>
                    <Form.Group controlId="rentalPeriod" className="mb-3">
                      <Form.Label>Rental Period</Form.Label>
                      <Form.Control as="select" onChange={e => { setRentalPeriod(e.target.value) }} value={rentalPeriod} className={`form-control-select-ads ${rentalPeriod === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        <option value="Day">Day</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalPeriodError}</div>
                    </Form.Group>
                  </Col>)
              }
              <Col lg={6} md={6}>
                <Form.Group controlId="rentalCost" className="mb-3">
                  <Form.Label>Price<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Price"
                    value={rentalCost}
                    onChange={e => {
                      const value = e.target.value
                      const numericValue = value.replace(/\D/g, '')
                      setRentalCost(numericValue)
                    }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalCostError}</div>
                </Form.Group>
              </Col>
              {
                adsTypeRentalData && (
                  <div className='ads-post-btn'>
                    <Button onClick={() => priceAdd()} className="mt-4 w-20 btn btn-warning">
                      Add
                    </Button>
                  </div>
                )}
              <Col>
                <Table className="priceTable">
                  {
                    (dayPrice != "" || monthPrice != "" || weekPrice != "") && (
                      <tr>
                        <th>
                          Rental Period
                        </th>
                        <th>
                          Price
                        </th>
                      </tr>
                    )
                  }
                  {
                    dayPrice != "" && (
                      <tr>
                        <td>
                          Day
                        </td>
                        <td>
                          {dayPrice}
                        </td>
                      </tr>)
                  }
                  {
                    weekPrice != "" && (
                      <tr>
                        <td>
                          Week
                        </td>
                        <td>
                          {weekPrice}
                        </td>
                      </tr>)
                  }
                  {
                    monthPrice != "" && (
                      <tr>
                        <td>
                          Month
                        </td>
                        <td>
                          {monthPrice}
                        </td>
                      </tr>)
                  }
                </Table>
              </Col>
            </Row>
            <div className='ads-post-btn'>
              <Button type='submit' className="mt-4 w-25 btn btn-warning">
                Post Ad
              </Button>
            </div>
          </Form>
        </div>
        <br />
      </div>
      <div id="post_ads" className="mobile-view">
        <div className="post-ads-form-box-mobile hero">
          <h2 className="mb-4">Post Ad</h2>
          <Form noValidate onSubmit={SubmitForm} >
            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Advertisement Title<span className='red-color-label'>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={e => { setTitle(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{titleError}</div>
            </Form.Group>
            <Form.Group controlId="type" className="mb-3">
              <Form.Label>Advertisement Type<span className='red-color-label'>*</span></Form.Label>
              <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                <option value="Rental">Rental</option>
                <option value="Service">Service</option>
                <option value="Trading">Trading</option>
              </Form.Control>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{typeError}</div>
            </Form.Group>
            <Form.Group controlId="area" className="mb-3">
              <Form.Label>Area Available for Work<span className='red-color-label'>*</span></Form.Label>
              <div className="dropdown form-control">
                <div className='form-control-select-ads'>
                  {areasName.length !== 0 ? (areasName.join(', ')) : ("Select Areas")}
                </div>
                <div className="dropdown-content">
                  {
                    areas.map((res, index) =>
                      <div className='d-flex dropdown-bottom-box' key={res.id}>
                        <label class="form-check-label dropdown-bottom-label" htmlFor={`checkbox-${res.id}`} key={res.id}>
                          {res.a_name}
                        </label>
                        <input
                          class="form-check-input ms-auto"
                          type="checkbox"
                          id={`checkbox-${res.id}`}
                          value={res.id}
                          name={res.a_name}
                          onChange={(e) => areaCheckboxChange(e)}
                          checked={areasName.includes(res.a_name) ? (true) : (false)}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{areaError}</div>
            </Form.Group>
            <Form.Group controlId="category" className="mb-3">
              <Form.Label>Category<span className='red-color-label'>*</span></Form.Label>
              <Form.Control as="select" value={category} onChange={e => { setCategory(e.target.value) }} className={`form-control-select-ads ${category === "" ? "selected-default-option" : ""}`}>
                <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                {
                  allCategory.map((res, index) =>
                    <option value={res.id}>{res.name}</option>
                  )
                }
              </Form.Control>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{categoryError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Form.Group controlId="make" className="mb-3">
                  <Form.Label>Make<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter make"
                    value={make}
                    onChange={e => { setMake(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{makeError}</div>
                </Form.Group>)
            }
            <Form.Group controlId="description" className="mb-3">
              {
                adsTypeRentalData ? (<Form.Label>Machine Description<span className='red-color-label'>*</span></Form.Label>) : (<Form.Label>Description<span className='red-color-label'>*</span></Form.Label>)}
              <Form.Control
                as="textarea"
                rows={4}
                value={description}
                placeholder="Enter machine description"
                onChange={e => { setDescription(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{descriptionError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="model" className="mb-3">
                      <Form.Label>Model<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter model"
                        value={model}
                        onChange={e => { setModel(e.target.value) }}
                      />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{modelError}</div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="yom" className="mb-3">
                      <Form.Label>Year of Manufacture<span className='red-color-label'>*</span></Form.Label>
                      <Form.Control as="select" value={manufacture} onChange={e => { setManufacture(e.target.value) }} className={`form-control-select-ads ${manufacture === "" ? "selected-default-option" : ""}`}>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {Array.from({ length: 2024 - 1960 + 1 }, (_, index) => (
                          <option key={index} value={2024 - index}>{2024 - index}</option>
                        ))}
                      </Form.Control>
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{manufactureError}</div>
                    </Form.Group>
                  </Col>
                </Row>)
            }
            <Form.Group controlId="photos" className="mb-3">
              <Form.Label>Upload Photos (up to 5)<span className='red-color-label'>*</span></Form.Label>
              <Form.Control type="file" accept="image/*" onChange={onChangeHandler} ref={fileInputRef1} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{imageError}</div>
              <div className="mt-3">
                <Row>
                  <Col>
                    <img
                      src={image1}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                  </Col>
                  <Col>
                    <img
                      src={image2}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                  </Col>
                  <Col>
                    <img
                      src={image3}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                  </Col>
                  <Col>
                    <img
                      src={image4}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                  </Col>
                  <Col>
                    <img
                      src={image5}
                      className="img-thumbnail"
                      style={{ width: 'auto', marginRight: '10px' }}
                      onClick={clickImageUpload}
                    />
                  </Col>
                </Row>
              </div>
            </Form.Group>
            <Form.Group controlId="phone_number" className="mb-3">
              <Form.Label>Telephone Number<span className='red-color-label'>*</span></Form.Label>
              <InputGroup>
                <Form.Select
                  aria-label="Country code"
                  style={{ maxWidth: '120px' }}
                  value={telCountryCode}
                  onChange={(e) => setTelCountryCode(e.target.value)}
                >
                  <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                  <option data-countryCode="AD" value="376">Andorra (+376)</option>
                  <option data-countryCode="AO" value="244">Angola (+244)</option>
                  <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                  <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                  <option data-countryCode="AR" value="54">Argentina (+54)</option>
                  <option data-countryCode="AM" value="374">Armenia (+374)</option>
                  <option data-countryCode="AW" value="297">Aruba (+297)</option>
                  <option data-countryCode="AU" value="61">Australia (+61)</option>
                  <option data-countryCode="AT" value="43">Austria (+43)</option>
                  <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                  <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                  <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                  <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                  <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                  <option data-countryCode="BY" value="375">Belarus (+375)</option>
                  <option data-countryCode="BE" value="32">Belgium (+32)</option>
                  <option data-countryCode="BZ" value="501">Belize (+501)</option>
                  <option data-countryCode="BJ" value="229">Benin (+229)</option>
                  <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                  <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                  <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                  <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                  <option data-countryCode="BW" value="267">Botswana (+267)</option>
                  <option data-countryCode="BR" value="55">Brazil (+55)</option>
                  <option data-countryCode="BN" value="673">Brunei (+673)</option>
                  <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                  <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                  <option data-countryCode="BI" value="257">Burundi (+257)</option>
                  <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                  <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                  <option data-countryCode="CA" value="1">Canada (+1)</option>
                  <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                  <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                  <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                  <option data-countryCode="CL" value="56">Chile (+56)</option>
                  <option data-countryCode="CN" value="86">China (+86)</option>
                  <option data-countryCode="CO" value="57">Colombia (+57)</option>
                  <option data-countryCode="KM" value="269">Comoros (+269)</option>
                  <option data-countryCode="CG" value="242">Congo (+242)</option>
                  <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                  <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                  <option data-countryCode="HR" value="385">Croatia (+385)</option>
                  <option data-countryCode="CU" value="53">Cuba (+53)</option>
                  <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                  <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                  <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                  <option data-countryCode="DK" value="45">Denmark (+45)</option>
                  <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                  <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                  <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                  <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                  <option data-countryCode="EG" value="20">Egypt (+20)</option>
                  <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                  <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                  <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                  <option data-countryCode="EE" value="372">Estonia (+372)</option>
                  <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                  <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                  <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                  <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                  <option data-countryCode="FI" value="358">Finland (+358)</option>
                  <option data-countryCode="FR" value="33">France (+33)</option>
                  <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                  <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                  <option data-countryCode="GA" value="241">Gabon (+241)</option>
                  <option data-countryCode="GM" value="220">Gambia (+220)</option>
                  <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                  <option data-countryCode="DE" value="49">Germany (+49)</option>
                  <option data-countryCode="GH" value="233">Ghana (+233)</option>
                  <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                  <option data-countryCode="GR" value="30">Greece (+30)</option>
                  <option data-countryCode="GL" value="299">Greenland (+299)</option>
                  <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                  <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                  <option data-countryCode="GU" value="671">Guam (+671)</option>
                  <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                  <option data-countryCode="GN" value="224">Guinea (+224)</option>
                  <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                  <option data-countryCode="GY" value="592">Guyana (+592)</option>
                  <option data-countryCode="HT" value="509">Haiti (+509)</option>
                  <option data-countryCode="HN" value="504">Honduras (+504)</option>
                  <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                  <option data-countryCode="HU" value="36">Hungary (+36)</option>
                  <option data-countryCode="IS" value="354">Iceland (+354)</option>
                  <option data-countryCode="IN" value="91">India (+91)</option>
                  <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                  <option data-countryCode="IR" value="98">Iran (+98)</option>
                  <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                  <option data-countryCode="IE" value="353">Ireland (+353)</option>
                  <option data-countryCode="IL" value="972">Israel (+972)</option>
                  <option data-countryCode="IT" value="39">Italy (+39)</option>
                  <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                  <option data-countryCode="JP" value="81">Japan (+81)</option>
                  <option data-countryCode="JO" value="962">Jordan (+962)</option>
                  <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                  <option data-countryCode="KE" value="254">Kenya (+254)</option>
                  <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                  <option data-countryCode="KP" value="850">Korea North (+850)</option>
                  <option data-countryCode="KR" value="82">Korea South (+82)</option>
                  <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                  <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                  <option data-countryCode="LA" value="856">Laos (+856)</option>
                  <option data-countryCode="LV" value="371">Latvia (+371)</option>
                  <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                  <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                  <option data-countryCode="LR" value="231">Liberia (+231)</option>
                  <option data-countryCode="LY" value="218">Libya (+218)</option>
                  <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                  <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                  <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                  <option data-countryCode="MO" value="853">Macao (+853)</option>
                  <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                  <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                  <option data-countryCode="MW" value="265">Malawi (+265)</option>
                  <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                  <option data-countryCode="MV" value="960">Maldives (+960)</option>
                  <option data-countryCode="ML" value="223">Mali (+223)</option>
                  <option data-countryCode="MT" value="356">Malta (+356)</option>
                  <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                  <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                  <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                  <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                  <option data-countryCode="MX" value="52">Mexico (+52)</option>
                  <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                  <option data-countryCode="MD" value="373">Moldova (+373)</option>
                  <option data-countryCode="MC" value="377">Monaco (+377)</option>
                  <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                  <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                  <option data-countryCode="MA" value="212">Morocco (+212)</option>
                  <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                  <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                  <option data-countryCode="NA" value="264">Namibia (+264)</option>
                  <option data-countryCode="NR" value="674">Nauru (+674)</option>
                  <option data-countryCode="NP" value="977">Nepal (+977)</option>
                  <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                  <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                  <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                  <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                  <option data-countryCode="NE" value="227">Niger (+227)</option>
                  <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                  <option data-countryCode="NU" value="683">Niue (+683)</option>
                  <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                  <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                  <option data-countryCode="NO" value="47">Norway (+47)</option>
                  <option data-countryCode="OM" value="968">Oman (+968)</option>
                  <option data-countryCode="PW" value="680">Palau (+680)</option>
                  <option data-countryCode="PA" value="507">Panama (+507)</option>
                  <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                  <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                  <option data-countryCode="PE" value="51">Peru (+51)</option>
                  <option data-countryCode="PH" value="63">Philippines (+63)</option>
                  <option data-countryCode="PL" value="48">Poland (+48)</option>
                  <option data-countryCode="PT" value="351">Portugal (+351)</option>
                  <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                  <option data-countryCode="QA" value="974">Qatar (+974)</option>
                  <option data-countryCode="RE" value="262">Reunion (+262)</option>
                  <option data-countryCode="RO" value="40">Romania (+40)</option>
                  <option data-countryCode="RU" value="7">Russia (+7)</option>
                  <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                  <option data-countryCode="SM" value="378">San Marino (+378)</option>
                  <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                  <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                  <option data-countryCode="SN" value="221">Senegal (+221)</option>
                  <option data-countryCode="CS" value="381">Serbia (+381)</option>
                  <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                  <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                  <option data-countryCode="SG" value="65">Singapore (+65)</option>
                  <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                  <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                  <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                  <option data-countryCode="SO" value="252">Somalia (+252)</option>
                  <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                  <option data-countryCode="ES" value="34">Spain (+34)</option>
                  <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                  <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                  <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                  <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                  <option data-countryCode="SD" value="249">Sudan (+249)</option>
                  <option data-countryCode="SR" value="597">Suriname (+597)</option>
                  <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                  <option data-countryCode="SE" value="46">Sweden (+46)</option>
                  <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                  <option data-countryCode="SI" value="963">Syria (+963)</option>
                  <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                  <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                  <option data-countryCode="TH" value="66">Thailand (+66)</option>
                  <option data-countryCode="TG" value="228">Togo (+228)</option>
                  <option data-countryCode="TO" value="676">Tonga (+676)</option>
                  <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                  <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                  <option data-countryCode="TR" value="90">Turkey (+90)</option>
                  <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                  <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                  <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                  <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                  <option data-countryCode="UG" value="256">Uganda (+256)</option>
                  <option data-countryCode="GB" value="44">UK (+44)</option>
                  <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                  <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                  <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                  <option data-countryCode="US" value="1">USA (+1)</option>
                  <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                  <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                  <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                  <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                  <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                  <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                  <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                  <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                  <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                  <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                  <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                  <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                </Form.Select>
                <Form.Control
                  type="text"
                  placeholder="Enter telephone number"
                  value={phone}
                  onChange={e => {
                    const value = e.target.value;
                    const numericValue = value.replace(/\D/g, '');
                    setPhone(numericValue);
                  }}
                />
              </InputGroup>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{phoneError}</div>
            </Form.Group>
            <Form.Group controlId="whatsapp" className="mb-3">
              <Form.Label>Mobile<span className='red-color-label'>*</span></Form.Label>
              <InputGroup>
                <Form.Select
                  aria-label="Country code"
                  style={{ maxWidth: '120px' }}
                  value={mobileCountryCode}
                  onChange={(e) => setMobileCountryCode(e.target.value)}
                >
                  <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                  <option data-countryCode="AD" value="376">Andorra (+376)</option>
                  <option data-countryCode="AO" value="244">Angola (+244)</option>
                  <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                  <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                  <option data-countryCode="AR" value="54">Argentina (+54)</option>
                  <option data-countryCode="AM" value="374">Armenia (+374)</option>
                  <option data-countryCode="AW" value="297">Aruba (+297)</option>
                  <option data-countryCode="AU" value="61">Australia (+61)</option>
                  <option data-countryCode="AT" value="43">Austria (+43)</option>
                  <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                  <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                  <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                  <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                  <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                  <option data-countryCode="BY" value="375">Belarus (+375)</option>
                  <option data-countryCode="BE" value="32">Belgium (+32)</option>
                  <option data-countryCode="BZ" value="501">Belize (+501)</option>
                  <option data-countryCode="BJ" value="229">Benin (+229)</option>
                  <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                  <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                  <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                  <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                  <option data-countryCode="BW" value="267">Botswana (+267)</option>
                  <option data-countryCode="BR" value="55">Brazil (+55)</option>
                  <option data-countryCode="BN" value="673">Brunei (+673)</option>
                  <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                  <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                  <option data-countryCode="BI" value="257">Burundi (+257)</option>
                  <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                  <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                  <option data-countryCode="CA" value="1">Canada (+1)</option>
                  <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                  <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                  <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                  <option data-countryCode="CL" value="56">Chile (+56)</option>
                  <option data-countryCode="CN" value="86">China (+86)</option>
                  <option data-countryCode="CO" value="57">Colombia (+57)</option>
                  <option data-countryCode="KM" value="269">Comoros (+269)</option>
                  <option data-countryCode="CG" value="242">Congo (+242)</option>
                  <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                  <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                  <option data-countryCode="HR" value="385">Croatia (+385)</option>
                  <option data-countryCode="CU" value="53">Cuba (+53)</option>
                  <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                  <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                  <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                  <option data-countryCode="DK" value="45">Denmark (+45)</option>
                  <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                  <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                  <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                  <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                  <option data-countryCode="EG" value="20">Egypt (+20)</option>
                  <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                  <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                  <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                  <option data-countryCode="EE" value="372">Estonia (+372)</option>
                  <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                  <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                  <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                  <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                  <option data-countryCode="FI" value="358">Finland (+358)</option>
                  <option data-countryCode="FR" value="33">France (+33)</option>
                  <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                  <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                  <option data-countryCode="GA" value="241">Gabon (+241)</option>
                  <option data-countryCode="GM" value="220">Gambia (+220)</option>
                  <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                  <option data-countryCode="DE" value="49">Germany (+49)</option>
                  <option data-countryCode="GH" value="233">Ghana (+233)</option>
                  <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                  <option data-countryCode="GR" value="30">Greece (+30)</option>
                  <option data-countryCode="GL" value="299">Greenland (+299)</option>
                  <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                  <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                  <option data-countryCode="GU" value="671">Guam (+671)</option>
                  <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                  <option data-countryCode="GN" value="224">Guinea (+224)</option>
                  <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                  <option data-countryCode="GY" value="592">Guyana (+592)</option>
                  <option data-countryCode="HT" value="509">Haiti (+509)</option>
                  <option data-countryCode="HN" value="504">Honduras (+504)</option>
                  <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                  <option data-countryCode="HU" value="36">Hungary (+36)</option>
                  <option data-countryCode="IS" value="354">Iceland (+354)</option>
                  <option data-countryCode="IN" value="91">India (+91)</option>
                  <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                  <option data-countryCode="IR" value="98">Iran (+98)</option>
                  <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                  <option data-countryCode="IE" value="353">Ireland (+353)</option>
                  <option data-countryCode="IL" value="972">Israel (+972)</option>
                  <option data-countryCode="IT" value="39">Italy (+39)</option>
                  <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                  <option data-countryCode="JP" value="81">Japan (+81)</option>
                  <option data-countryCode="JO" value="962">Jordan (+962)</option>
                  <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                  <option data-countryCode="KE" value="254">Kenya (+254)</option>
                  <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                  <option data-countryCode="KP" value="850">Korea North (+850)</option>
                  <option data-countryCode="KR" value="82">Korea South (+82)</option>
                  <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                  <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                  <option data-countryCode="LA" value="856">Laos (+856)</option>
                  <option data-countryCode="LV" value="371">Latvia (+371)</option>
                  <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                  <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                  <option data-countryCode="LR" value="231">Liberia (+231)</option>
                  <option data-countryCode="LY" value="218">Libya (+218)</option>
                  <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                  <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                  <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                  <option data-countryCode="MO" value="853">Macao (+853)</option>
                  <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                  <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                  <option data-countryCode="MW" value="265">Malawi (+265)</option>
                  <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                  <option data-countryCode="MV" value="960">Maldives (+960)</option>
                  <option data-countryCode="ML" value="223">Mali (+223)</option>
                  <option data-countryCode="MT" value="356">Malta (+356)</option>
                  <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                  <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                  <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                  <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                  <option data-countryCode="MX" value="52">Mexico (+52)</option>
                  <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                  <option data-countryCode="MD" value="373">Moldova (+373)</option>
                  <option data-countryCode="MC" value="377">Monaco (+377)</option>
                  <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                  <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                  <option data-countryCode="MA" value="212">Morocco (+212)</option>
                  <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                  <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                  <option data-countryCode="NA" value="264">Namibia (+264)</option>
                  <option data-countryCode="NR" value="674">Nauru (+674)</option>
                  <option data-countryCode="NP" value="977">Nepal (+977)</option>
                  <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                  <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                  <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                  <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                  <option data-countryCode="NE" value="227">Niger (+227)</option>
                  <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                  <option data-countryCode="NU" value="683">Niue (+683)</option>
                  <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                  <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                  <option data-countryCode="NO" value="47">Norway (+47)</option>
                  <option data-countryCode="OM" value="968">Oman (+968)</option>
                  <option data-countryCode="PW" value="680">Palau (+680)</option>
                  <option data-countryCode="PA" value="507">Panama (+507)</option>
                  <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                  <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                  <option data-countryCode="PE" value="51">Peru (+51)</option>
                  <option data-countryCode="PH" value="63">Philippines (+63)</option>
                  <option data-countryCode="PL" value="48">Poland (+48)</option>
                  <option data-countryCode="PT" value="351">Portugal (+351)</option>
                  <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                  <option data-countryCode="QA" value="974">Qatar (+974)</option>
                  <option data-countryCode="RE" value="262">Reunion (+262)</option>
                  <option data-countryCode="RO" value="40">Romania (+40)</option>
                  <option data-countryCode="RU" value="7">Russia (+7)</option>
                  <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                  <option data-countryCode="SM" value="378">San Marino (+378)</option>
                  <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                  <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                  <option data-countryCode="SN" value="221">Senegal (+221)</option>
                  <option data-countryCode="CS" value="381">Serbia (+381)</option>
                  <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                  <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                  <option data-countryCode="SG" value="65">Singapore (+65)</option>
                  <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                  <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                  <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                  <option data-countryCode="SO" value="252">Somalia (+252)</option>
                  <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                  <option data-countryCode="ES" value="34">Spain (+34)</option>
                  <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                  <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                  <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                  <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                  <option data-countryCode="SD" value="249">Sudan (+249)</option>
                  <option data-countryCode="SR" value="597">Suriname (+597)</option>
                  <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                  <option data-countryCode="SE" value="46">Sweden (+46)</option>
                  <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                  <option data-countryCode="SI" value="963">Syria (+963)</option>
                  <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                  <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                  <option data-countryCode="TH" value="66">Thailand (+66)</option>
                  <option data-countryCode="TG" value="228">Togo (+228)</option>
                  <option data-countryCode="TO" value="676">Tonga (+676)</option>
                  <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                  <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                  <option data-countryCode="TR" value="90">Turkey (+90)</option>
                  <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                  <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                  <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                  <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                  <option data-countryCode="UG" value="256">Uganda (+256)</option>
                  <option data-countryCode="GB" value="44">UK (+44)</option>
                  <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                  <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                  <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                  <option data-countryCode="US" value="1">USA (+1)</option>
                  <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                  <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                  <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                  <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                  <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                  <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                  <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                  <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                  <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                  <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                  <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                  <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                </Form.Select>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile"
                  value={whatsapp}
                  onChange={e => {
                    const value = e.target.value
                    const numericValue = value.replace(/\D/g, '')
                    setWhatsapp(numericValue)
                  }}
                />
              </InputGroup>
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{whatsappError}</div>
            </Form.Group>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email address<span className='red-color-label'>*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => { setEmail(e.target.value) }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            {
              type == "Trading" && (
                <Form.Group controlId="usage" className="mb-3">
                  <Form.Label>Usage (hours)<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Usage (hours)"
                    value={usage}
                    onChange={e => { setUsage(e.target.value) }}
                  />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{usageError}</div>
                </Form.Group>)
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="pickupOrDelivery" className="mb-3">
                  <Form.Label>Delivery Option<span className='red-color-label'>*</span></Form.Label>
                  <Form.Control as="select" onChange={e => { setpickupOrDelivery(e.target.value) }} value={pickupOrDelivery} className={`form-control-select-ads ${pickupOrDelivery === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="Pickup">Pickup</option>
                    <option value="Delivery">Delivery</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{pickupOrDeliveryError}</div>
                </Form.Group>
              )
            }
            {
              adsTypeRentalData && (
                <hr />
              )}
            {
              adsTypeRentalData && (
                <Form.Group controlId="rentalPeriod" className="mb-3">
                  <Form.Label>Rental Period</Form.Label>
                  <Form.Control as="select" onChange={e => { setRentalPeriod(e.target.value) }} value={rentalPeriod} className={`form-control-select-ads ${rentalPeriod === "" ? "selected-default-option" : ""}`}>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                  </Form.Control>
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalPeriodError}</div>
                </Form.Group>)
            }
            <Form.Group controlId="rentalCost" className="mb-3">
              <Form.Label>Price<span className='red-color-label'>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Price"
                value={rentalCost}
                onChange={e => {
                  const value = e.target.value
                  const numericValue = value.replace(/\D/g, '')
                  setRentalCost(numericValue)
                }}
              />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{rentalCostError}</div>
            </Form.Group>
            {
              adsTypeRentalData && (
                <div className='ads-post-btn'>
                  <Button onClick={() => priceAdd()} className="mt-4 w-20 btn btn-warning">
                    Add
                  </Button>
                </div>
              )}
            <Table className="priceTable">
              {
                (dayPrice != "" || monthPrice != "" || weekPrice != "") && (
                  <tr>
                    <th>
                      Rental Period
                    </th>
                    <th>
                      Price
                    </th>
                  </tr>
                )
              }
              {
                dayPrice != "" && (
                  <tr>
                    <td>
                      Day
                    </td>
                    <td>
                      {dayPrice}
                    </td>
                  </tr>)
              }
              {
                weekPrice != "" && (
                  <tr>
                    <td>
                      Week
                    </td>
                    <td>
                      {weekPrice}
                    </td>
                  </tr>)
              }
              {
                monthPrice != "" && (
                  <tr>
                    <td>
                      Month
                    </td>
                    <td>
                      {monthPrice}
                    </td>
                  </tr>)
              }
            </Table>
            <div className='ads-post-btn'>
              <Button type="submit" className="mt-4 w-25 btn btn-warning">
                Post Ad
              </Button>
            </div>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Post_ads;
